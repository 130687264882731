.balance_title {
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #F6F6F6;
}


.balance_box {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
}

.main {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    overflow: hidden;
    position: relative;
    gap: 24px;
}

.boosts {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.boost_items {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 8px;
}

.boost_title {
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #F6F6F6;
}