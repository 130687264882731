.header {
    padding: 8px 0px 0 0px;
}

.header_content {
    height: 94px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 16px;
    gap: 16px;
    background: linear-gradient(90deg, #174789 0%, #247CBB 45%, #349CD9 100%);
    border-radius: 10px;
}

.cubok_left {
    position: absolute;
    left: 0;
    z-index: 0;
    top: 5px;
}

.cubok_right {
    position: absolute;
    right: 0;
    z-index: 0;
    top: 7px;
}

.header_content_top {
    display: flex;
    align-items: center;
    gap: 4px;
    position: relative;
    z-index: 1;
}

.header_content_top a {
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header_content_top_title {
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #F6F6F6;
}


.power_title {
    font-family: Inter, sans-serif;
    font-size: 11px;
    font-weight: 700;
    line-height: 14px;
    /*color: #174789;*/
    color: #174789;
    display: flex;
    align-items: center;
    gap: 2px;
    position: relative;
    z-index: 4;
}