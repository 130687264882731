.navigate {
    padding: 8px 16px 0 16px;
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 86px;
}

.navigate * {
    user-select: none;
}

.navigate_content {
    height: 54px;
    display: grid;
    padding: 4px;
    grid-template-columns: repeat(5, 1fr);
    border-radius: 10px;
    gap: 5px;
    background: linear-gradient(90deg, #174789 0%, #247CBB 45%, #349CD9 100%);
}

.navigate_item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 8px;
    transition: .4s all;
    text-decoration: none;
}

.navigate_title {
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
    color: #F6F6F6;
    transition: .4s all;
}

.navigate_item svg path {
    fill: #F6F6F6;
    transition: .4s all;
}

.navigate_item_active {
    background: #152740;
}

.navigate_item_active svg path {
    fill: #A1DCFF;
}

.navigate_item_active .navigate_title {
    color: #A1DCFF;
}