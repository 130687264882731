.main {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    overflow: hidden;
    position: relative;
}

.content {
    margin-top: 16px;
    width: 100%;
}

.title {
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    color: #F6F6F6;
}

.description_box {
    padding: 16px;
    border: 1px solid #349CD9;
    border-radius: 10px;
    background: #2824564D;
    margin-top: 20px;
    margin-bottom: 20px;
}

.description_box_title {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    color: #F6F6F6;
}

.description_box_items {
    margin: 4px 0 8px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.item {
    display: flex;
    align-items: center;
    gap: 4px;
}

.number {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #174789 0%, #247CBB 45%, #349CD9 100%);
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    color: #F6F6F6;
    border-radius: 50%;
}

.item_title {
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    text-align: left;
    color: #F6F6F6;
}

.tutorial_title {
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    text-decoration: underline;
    color: #A1DCFF;
}

.watch_video {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btns {
    display: flex;
    align-items: center;
    gap: 8px;
}

.btn {
    height: 40px !important;
}

.btn_copy {
    width: 40px !important;
}

.navigate {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 10px;
    height: 42px;
    width: 100%;
    background: linear-gradient(90deg, #174789 0%, #247CBB 45%, #349CD9 100%);
    padding: 4px;
    margin-top: 24px;
    gap: 8px;
}

.link {
    width: 100%;
    text-decoration: none;
    height: 100%;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    color: #F6F6F6;
    transition: .4s all;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.link_active {
    color: #A1DCFF;
    background: #152740;
}