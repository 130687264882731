
.skeleton {
    padding: 14px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}


.circle {
    height: 260px;
    width: 260px;
    border-radius: 50%;
    background: #174789;
    background: linear-gradient(45deg, #174789, #1e5799, #2e6799, #3e7799, #4e8799);

    display: flex;
    align-items: center;
    justify-content: center;
    /*overflow: hidden;*/
    position: relative;
    /*border-top: 3px solid #A1DCFF;*/
    transition: .4s all;
}

.logo{
    position: absolute;
    z-index: 999999;
}

.logo img {
    height: 120px;
    width: 120px;
}

.circle_active {
    position: absolute;
    /*border: 1px solid lightcoral;*/
    background: #17478933;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    border-radius: 50%;

}

.circle_active_v2 {
    position: absolute;
    background: #1747891A;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    border-radius: 50%;
}

.rotating_line {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-sizing: border-box;
    background: conic-gradient(from 0deg, rgba(86, 154, 245, 0) 90deg, #5594f1 450deg);
    mask: radial-gradient(farthest-side, transparent calc(100% - 5px), black calc(100% - 0px));
}