.main {
    height: 100%;
    padding: 58px 16px 0 16px;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.arrow_box {
    position: absolute;
    width: 100%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0 0px;
    z-index: 2;
}

.arrow_left {
    transform: rotate(180deg);
}

.arrow_left, .arrow_right {
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow_left svg, .arrow_right svg {
    width: 55px;
    height: 55px;
}

.item {
    width: 100%;
}

.item_title {
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    color: #F6F6F6;
}

.item_description {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #F6F6F6;
    margin-top: 8px;
}

.cubok {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 60px 0;
}

.cubok img {
    width: 164px;
    height: 220px;
}

.info_box {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.info_title {
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: #F6F6F6;
}