
.content {
    width: 100%;
    display: flex;
    align-items: center;
    /*width: 100%;*/
    justify-content: center;
    flex-direction: column;
    gap: 24px;
}

.content_wallet_icon svg {
    height: 80px;
    width: 80px;
}

.content_description {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
}

.tutorial_title {
    margin-top: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    text-decoration: underline;
    color: #A1DCFF;
}

.title {
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    color: #F6F6F6;
}

.sub_title {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #F6F6F6;
}

.btns {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    margin-top: auto !important;
}