.item {
    display: flex;
    flex-direction: column;
    padding: 8px 12px;
    min-height: 56px;
    border-radius: 10px;
    background: linear-gradient(90deg, #1A5B86 0%, #194E7B 45%, #133260 100%);
    box-shadow: 5px 5px 10px 0px #4F6B784D inset;
    position: relative;

}

.item:before {
    content: '';
    position: absolute;
    /*border: 1px solid red;*/
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 10px;
    box-shadow: -5px -5px 10px 0px #03183880 inset;
    z-index: 3;
}

.item_used {
    box-shadow: 5px 5px 10px 0px #4F6B784D inset;
    background: linear-gradient(90deg, rgba(26, 91, 134, 0.6) 0%, rgba(25, 78, 123, 0.6) 45%, rgba(19, 50, 96, 0.6) 100%);

}

.item_used:before {
    content: '';
    position: absolute;
    /*border: 1px solid red;*/
    width: 100%;
    height: 100%;
    left: 0;
    border-radius: 10px;
    box-shadow: 5px 5px 10px 0px #4F6B784D inset;
    z-index: 3;
}

.item_left {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 100%;

}

.item_left_info {
    /*border: 1px solid red;*/
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.item_left_info_title {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #F6F6F6;
}

.item_left_info_bottom_title {
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #F6F6F6;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
}

.icon_box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 4;
    /*border: 1px solid red;*/
    height: 100%;
    width: 43px;
    padding-left: 12px;
}

.btn {
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #F6F6F6;
    padding: 6px 8px;
    border-radius: 10px;
    background: linear-gradient(180deg, #549DF2 0%, #1A5DAB 100%);
    margin-left: -12px;
    transition: .4s all;
}

.btn:active {
    background: linear-gradient(180deg, rgba(84, 157, 242, 0.71) 0%, rgba(26, 93, 171, 0.58) 100%);
}

.btn_disabled {
    background: #174789 !important;
    color: #607EA1 !important;
}

.item_wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.progress_box {
    margin-top: 8px;
    width: 100%;
}

.progress_title {
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 800;
    line-height: 16px;
    text-align: center;
    color: #174789;
    position: relative;
    z-index: 4;
}