.main {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 8px;
    /*padding-top: 58px;*/
}

.title {
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    color: #F6F6F6;
}

.sub_title {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #F6F6F6;
}

.btns {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    margin-top: 24px;
    gap: 32px;
}

.wallet_connect_info {
    padding: 16px;
    width: 100%;
    background: linear-gradient(90deg, #174789 0%, #247CBB 45%, #349CD9 100%);
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 10px;
}

.wallet_connect_info_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
}

.wallet_connect_info_top_left {
    display: flex;
    align-items: center;
    gap: 8px;
}

.wallet_connect_info_top_left_title {
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #F6F6F6;
}

.wallet_connect_info_top_right svg {
    height: 24px;
    width: 24px;
}

