.app {
    width: 100%;
    min-height: calc(100dvh + 0px);
    min-height: 100vh;
    background-image: url('./assets/background.png');
    background-size: cover;
    background-attachment: fixed;
}

.tonConnectButton button {
    width: 100% !important;
    text-align: center !important;
    justify-content: center !important;
    align-items: center !important;
}

.tonConnectButton {
    z-index: 999999999999999999 !important;
}

.content {
    display: grid;
    overflow: auto;
    height: 100%;
    min-height: calc(100vh - 86px);
    max-height: calc(100vh - 86px);
    /*max-height: 100%;*/
}

.app_preloader {
    max-height: 100vh;
    height: 100vh;
    overflow: hidden;
}
