.content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /*gap: 24px;*/
}

.title {
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #F6F6F6;
    width: 100%;
}

.description {
    font-family: Inter, sans-serif;
    width: 100%;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
    color: #F6F6F6;
    margin-top: 8px;
}

.verif_btn img {
    width: 22px;
    height: 22px;
    margin-left: 6px;
}

.box {
    padding: 16px;
    border-radius: 10px;
    background: linear-gradient(90deg, #1A5B86 0%, #194E7B 45%, #133260 100%);
    gap: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 16px 0;
}

.box_title {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    color: #F6F6F6;
}

.get_cost_box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    color: #F6F6F6;
}

.btn_content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #F6F6F6;
}

.btn_content img {
    width: 22px;
    height: 22px;
}

.input {
    width: calc(100% - 32px);
    padding: 12px 16px 12px 16px;

    background: #A1DCFF80;
    border: none;
    outline: none;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #F6F6F6;
    border-radius: 8px;
}

.input::placeholder {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #F6F6F6;
}

.btn_content svg {
    width: 22px;
    height: 22px;
}

.link {
    width: 100%;
    text-decoration: none;
}

.btn_style {
    height: 40px !important;
}