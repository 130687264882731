.main {
    min-height: 100vh;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: space-between;
    gap: 14px;
    flex-direction: column;
    padding: 32px 0;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    /*display: none;*/
    z-index: 99;
    background-image: url('/src/assets/background.png');
    background-size: cover;
    background-attachment: fixed;
}

.circle {
    height: 260px;
    width: 260px;
    border-radius: 50%;
    background: #174789;
    background: linear-gradient(45deg, #174789, #1e5799, #2e6799, #3e7799, #4e8799);

    display: flex;
    align-items: center;
    justify-content: center;
    /*overflow: hidden;*/
    position: relative;
    /*border-top: 3px solid #A1DCFF;*/
    transition: .4s all;
}

.logo svg {
    height: 120px;
    width: 120px;
}

.circle_active {
    position: absolute;
    /*border: 1px solid lightcoral;*/
    background: #17478933;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    border-radius: 50%;

}

.circle_active_v2 {
    position: absolute;
    background: #1747891A;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    border-radius: 50%;
}


.logo {
    position: relative;
    z-index: 2;
}

.rotating_line {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-sizing: border-box;
    background: conic-gradient(from 0deg, rgba(86, 154, 245, 0) 90deg, #5594f1 450deg);
    mask: radial-gradient(farthest-side, transparent calc(100% - 5px), black calc(100% - 0px));
}

.title {
    font-family: Inter, sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    color: #F6F6F6;

}

.info_box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    flex-direction: column;
    gap: 24px;
}

.description {
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #F6F6F6;
}

.social_link_box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.social_link_box a {
    text-decoration: none;
    width: 60px;
    height: 60px;
}

.social_link_box a img {
    width: 60px;
    height: 60px;
}