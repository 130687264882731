.title {
    font-family: Inter, sans-serif;
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
    text-align: center;
    color: #F6F6F6;
}

.table {
    width: 100%;
    margin-top: 16px;
}

.table_header_item {
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    text-align: left;
    color: #F6F6F6;
    flex: 1;
}

.table_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 7px;
    padding: 0 12px;
}

.table_header_item_first {
    width: 35%;
    display: block;
    align-items: center;
    white-space: nowrap; /* Текст будет отображаться в одну строку */
    overflow: hidden; /* Обрезать текст, если он выходит за пределы */
    text-overflow: ellipsis;
}

.table_header_right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 7px;
    width: 65%;
}

.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 7px;
    background: linear-gradient(90deg, #1A5B86 0%, #194E7B 45%, #133260 100%);
    box-shadow: 5px 5px 10px 0px #4F6B784D inset;
    border-radius: 10px;
    padding: 8px 12px;
    min-height: 46px;
    position: relative;
}

.item:before {
    content: '';
    position: absolute;
    /*border: 1px solid red;*/
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 10px;
    box-shadow: -5px -5px 10px 0px #03183880 inset;
    z-index: 3;
}

.table_content {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    gap: 8px;
    margin-top: 8px;
}

.table_content_item {
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    color: #F6F6F6;
    flex: 1;
}

.table_content_item_counter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
}

.table_content_item_counter svg {
    height: 16px;
    width: 16px;
}

.table_content_item_level {
    flex: none;
    width: 28px;
}

.table_content_item_level img {
    width: 16px;
    height: 16px;
}

.pagination {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
}

.preloader {
    /*border: 1px solid red;*/
    height: 500px;
    width: calc(100% + 28px);
    margin-left: -14px;
    display: flex;
    align-items: center;
    justify-content: center;
}