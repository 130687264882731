.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 8px;
}

.icon_box svg {
    width: 80px;
    height: 80px;
}

.title {
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    color: #F6F6F6;
}

.info_box {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
}

.sub_title {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: #F6F6F6;

}

.sub_title_v2 {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: center;
    color: #F6F6F6;
    margin-top: -4px;
}

.coast_box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0 16px 0;
}

.coast_box_title {
    display: flex;
    align-items: center;
    gap: 4px;
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    color: #F6F6F6;
}

.coast_box_title svg {
    height: 40px;
    width: 40px;
}

.btn_box {
    width: 100%;
    padding: 16px 0 0 0;
}

.sub_title_type_1{

}