.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    min-height: 56px;
    border-radius: 10px;
    background: linear-gradient(90deg, #1A5B86 0%, #194E7B 45%, #133260 100%);
    box-shadow: 5px 5px 10px 0px #4F6B784D inset;
    position: relative;
}

.item_left_icon {
    min-height: 40px;
    min-width: 40px;
}

.item_left_icon svg {
    min-height: 40px;
    min-width: 40px;
}

.item:before {
    content: '';
    position: absolute;
    /*border: 1px solid red;*/
    width: 100%;
    height: 100%;
    left: 0;
    border-radius: 10px;
    box-shadow: -5px -5px 10px 0px #03183880 inset;
    z-index: 3;
}

.activated {
    position: relative;
    background: radial-gradient(50% 50% at 50% 50%, #A1DCFF 0%, #549DF2 100%);

    border-radius: 8px;
    padding: 13px;
    width: 38px;
    color: initial;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 0%;
}

/*.activated:before {*/
/*    content: '';*/
/*    position: absolute;*/
/*    height: 5px;*/
/*    width: 5px;*/
/*    border-radius: 50%;*/
/*    background: greenyellow;*/
/*    right: -8px;*/
/*    top: 3px;*/
/*}*/

.item_used {
    box-shadow: 5px 5px 10px 0px #4F6B784D inset;
    background: linear-gradient(90deg, rgba(26, 91, 134, 0.6) 0%, rgba(25, 78, 123, 0.6) 45%, rgba(19, 50, 96, 0.6) 100%);

}

.item_used:before {
    content: '';
    position: absolute;
    /*border: 1px solid red;*/
    width: 100%;
    height: 100%;
    left: 0;
    border-radius: 10px;
    box-shadow: 5px 5px 10px 0px #4F6B784D inset;
    z-index: 3;
}

.item_left {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 100%;

}

.item_left_info {
    /*border: 1px solid red;*/
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.item_left_info_title {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #F6F6F6;
}

.item_left_info_bottom_title {
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #F6F6F6;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
}

.icon_box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 4;
    /*border: 1px solid red;*/
    height: 100%;
    width: 43px;
    padding-left: 12px;
}