.main {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    overflow: hidden;
    justify-content: space-between;
    position: relative;
    transform: translateZ(0);
    --webkit-transform: translate3d(0, 0, 0);
}

.main_disabled:before {
    content: '';
    background: rgba(255, 255, 255, 0);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999999999;
}

.no_energy {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 99999999999999;
    font-family: Inter, sans-serif;
    text-align: center;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
}


.clickText {
    position: absolute;
    font-family: Inter, sans-serif;
    color: #fff;
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    pointer-events: none;
    z-index: 99999999;
    transform: translate(-50%, -100%); /* Center the text and position above the click point */
}


.click {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle_box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 282px;
    height: 282px;
    border-radius: 50%;
}

/*.icon_circle svg g {*/
/*    fill: red !important;*/
/*}*/

.world_box {
    position: absolute;
    border-radius: 50%;
    /*border: 1px solid red;*/
    overflow: hidden;
}

.world_box svg g .shadow_earth {
    transition: .4s all;
    fill: red !important;
}

.world_box_anim svg {

}

.air_box {
    position: absolute;
    width: 40px;
    height: 40px;
    transform-origin: center;
    animation: rotate 6s linear infinite;
}

@keyframes rotate {
    0% {
        transform: rotate(360deg) translateX(133px) rotate(-360deg);
    }
    100% {
        transform: rotate(0deg) translateX(133px) rotate(0deg);
    }
}

.neon_icon {
    transform-origin: center;
    animation: rotateNeon 6s linear infinite;
    transition: .4s all;
}

@keyframes rotateNeon {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.air_box svg {
    height: 100%;
    width: 100%;
    animation: rotateAir 6s linear infinite;
}

.air_box_active svg {
    height: 100%;
    width: 100%;
    animation: rotateAir 3s linear infinite;
}

.air_box_active {

    animation: rotate 3s linear infinite;
}

@keyframes rotateAir {
    0% {
        transform: rotate(310deg);

    }
    100% {
        transform: rotate(-50deg);
    }
}

.circle_box_dashed {
    border: 3px dashed #2e77e0;
    box-shadow: 0px 0px 30px 1px rgba(172, 224, 255, 0.2);
    position: absolute;
    height: 95%;
    width: 95%;
    border-radius: 50%;
}

.circle_box_dashed_active {
    border: 3px dashed #14BAA6;
    box-shadow: 0px 0px 30px 1px rgba(20, 186, 166, 0.4);
    transform-origin: center;
    animation: rotateNeon 6s linear infinite;
    transition: .4s all;
}


@keyframes rotateNeon {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.boost {
    padding: 8px 0 14px 0;
    width: 100%;
    margin: 0 auto;
    position: absolute;
    left: 0;
    z-index: 110;
    /*transform: translateZ(0);*/
    /*will-change: transform, opacity;*/
    /*--webkit-transform: translate3d(0, 0, 0);*/
}

.timer_box {
    position: relative;
    z-index: 99;
    height: 74px;
}

.boost_hidden {
    position: absolute;
    z-index: 100;
    left: -1000px;
}

.empty_timer {
    height: 76px;
}

.boost * {
    user-select: none;
}

.boost_box {
    height: 54px;
    /*width: 100%;*/
    margin: 0 auto;
    width: fit-content;
    border-radius: 10px;
    background: linear-gradient(90deg, rgba(23, 71, 137, 0.5) 0%, rgba(36, 124, 187, 0.5) 45%, rgba(52, 156, 217, 0.5) 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
}

.boost_timer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 4px;
}

.boost_timer_days {
    grid-template-columns: repeat(4, 1fr);
}

.boost_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60px;
}

.circle_box_icon {
    border-radius: 50%;
    padding: 0;
    margin: 0;
    border: 1px solid red;
}

.boost_count {
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #fff;
    position: relative;
    z-index: 2;
}

.boost_title {
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
    color: #A8B1D2;
}

.boost_main_title {
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    text-align: center;
    color: #F6F6F6;
}