.header_content_bottom {
    background: #F6F6F6;
    height: 16px;
    width: 100%;
    border-radius: 15px;
    padding: 1px;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.children {
    position: absolute;
    z-index: 10;
}

.progress {
    border-radius: 15px;
    transition: .4s all;
    position: absolute;
    left: 1px;
    top: 1px;
    height: calc(100% - 2px);
    width: 30%;
    background: linear-gradient(90deg, #174789 0%, #247CBB 45%, #349CD9 100%);

}