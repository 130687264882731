.counter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 8px;
}

.counter p {
    font-family: Inter, sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    color: #F6F6F6;
}

.counter img {
    height: 56px;
    width: 56px;
}