.navigate {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2.5px;
    padding: 4px;
    border-radius: 10px;
    margin-top: 28px;
    background: linear-gradient(90deg, #174789 0%, #247CBB 45%, #349CD9 100%);
}

.navigate a {
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 34px;
    border-radius: 8px;
    color: #F6F6F6;
    transition: .4s all;
    text-decoration: none;
}

.navigate .active {
    color: #A1DCFF;
    background: #152740;
}