.btn {
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    border-radius: 10px;
    background: #549DF2;
    transition: .4s all;
    width: 100%;
    user-select: none;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #F6F6F6;
}

.btn:active {
    background: rgb(113, 175, 246);
}

.btn_v2 {
    background: rgba(84, 157, 242, 0);
    border: 1px solid #549DF2;
    color: #549DF2;
}

.btn_v2:active {
    background: rgba(84, 157, 242, 0.1);
}

.btn_disabled {
    background: #3A3849 !important;
    color: #F6F6F6 !important;
}