.wallet_connect_info_bottom {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border-radius: 8px;
    background: #A1DCFF80;

}

.wallet_connect_info_bottom p {
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #F6F6F6;
}

.wallet_connect_info_bottom_v2 {
    background: linear-gradient(90deg, rgba(23, 71, 137, 0.4) 0%, rgba(36, 124, 187, 0.4) 45%, rgba(52, 156, 217, 0.4) 100%);

}
