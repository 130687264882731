.header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.connect_wallet {
    height: 100%;
    padding: 16px;
    position: relative;
    z-index: 999;
    border-radius: 20px 20px 0 0;
    background: #21202B;
    display: flex;
    flex-direction: column;
}

.connect_wallet_box {
    position: relative;
    z-index: 1;
    border-radius: 20px 20px 0 0;
    background: #21202B;
    height: 100%;
    display: flex;
    flex-direction: column;
    /*min-height: 436px;*/
}

.bg {
    position: absolute;
    top: -4px;
    z-index: 0;
    left: 2px;
    width: calc(100% - 4px);
    height: 32px;
    border-radius: 20px 20px 0 0;
    background: linear-gradient(90deg, #174789 0%, #247CBB 45%, #349CD9 100%);
}

.content {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
    gap: 8px;
    height: fit-content;
}